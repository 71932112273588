const WeatherLayers = window.WeatherLayers;

export async function initConfig() {
  const client = WeatherLayers.getClient();
  const stacCatalog = await client.loadStacCatalog();

  const config = {
    client,
    datasets: await client.loadStacCatalogChildCollectionIds(stacCatalog),
    dataset: 'gfs/wind_10m_above_ground',
    datetimes: [],
    datetime: new Date().toISOString(),
    datetimeInterpolate: true,
    imageInterpolate: true,
    rotate: false,
    raster: {
      enabled: false,
      colormap: 'default',
      opacity: 0.2,
    },
    particle: {
      enabled: true,
      numParticles: 5000,
      maxAge: 0,
      speedFactor: 0,
      width: WeatherLayers.DEFAULT_LINE_WIDTH,
      color: arrayToColor(WeatherLayers.DEFAULT_LINE_COLOR),
      opacity: 1,
      animate: true,
    },
  };

  await updateDataset(config);

  return config;
}

async function updateDataset(config) {
  const client = config.client;
  const stacCollection = await client.loadStacCollection(config.dataset);

  config.datetimes = client.getStacCollectionDatetimes(stacCollection);
  config.datetime = client.getStacCollectionClosestStartDatetime(stacCollection, config.datetime) || config.datetimes[0];

  config.particle.maxAge = stacCollection.summaries.particle?.maxAge || 0;
  config.particle.speedFactor = stacCollection.summaries.particle?.speedFactor || 0;
  config.particle.width = stacCollection.summaries.particle?.width || 0;
}

export function arrayToColor(color) {
  return { r: color[0], g: color[1], b: color[2], a: typeof color[3] === 'number' ? color[3] / 255 : 1 };
}

export function colorToArray(color) {
  return [color.r, color.g, color.b, ...(typeof color.a === 'number' ? [color.a * 255] : [255])];
}

export function isIOS15() {
  return navigator.maxTouchPoints && navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
}