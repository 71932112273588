<template>
  <div class="mapbox">
    <div class="container">
      <div id="mapbox"></div>
      <div id="deck"></div>

      <div class="map-overlay top">
        <button id="map-overlay-button" class="map-overlay-button">
          <img :src="icons.ship" alt="ship" />
        </button>

        <div id="map-overlay-bar" class="map-overlay-bar hide">
          <div class="map-overlay-bar-header">
            <div class="search">
              <a-input placeholder="Search" v-model:value="searchQuery" />

              <a href="" class="search-button">
                <SearchOutlined />
              </a>
            </div>
          </div>

          <div class="map-overlay-bar-body">
            <a
              v-for="item in searchedlist"
              :key="item.id"
              :class="{ active: item.voyage_id == state.voyage.voyage_id }"
              @click.prevent="selectVoyage(item)"
            >
              {{ item.vessel_name }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <TimelineChart />

    <div class="timeline">
      <a-slider
        v-model:value="state.sliderValue"
        :max="state.datetimes.length"
        :marks="state.datetimes"
        :included="false"
        :tooltipVisible="false"
        :handleStyle="{ 'background-color': 'red' }"
        @change="update"
      >
        <template #mark="{ label, point }">
          <template v-if="point === 0">
            <strong>{{ label }}</strong>
          </template>
          <template v-else>{{ label }}</template>
        </template>
      </a-slider>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import { format } from "date-fns";
import { icons } from "@/assets";
import { ZoomControl } from "mapbox-gl-controls";
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { initConfig, colorToArray, isIOS15 } from "./config.js";
import { useLoading } from "@/composables/useLoading";
import useVoyages from "@/composables/useVoyages";

import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl-controls/lib/controls.css";

export default defineComponent({
  name: "Chart",

  setup() {
    const searchQuery = ref("");
    const loading = useLoading();
    const voyages = useVoyages();

    const list = computed(() => voyages.voyages());

    const searchedlist = computed(() => {
      return list.value.filter((item) => {
        return (
          item.vessel_name
            .toLowerCase()
            .indexOf(searchQuery.value.toLowerCase()) != -1
        );
      });
    });

    const state = reactive({
      sliderValue: 0,
      datetimes: [],
      voyage: {},
    });

    const selectVoyage = (item) => {
      state.voyage = item;
    };

    const currentDatetime = computed(() => {
      return state.datetimes[state.sliderValue].value;
    });

    let config = null;
    let deckgl = null;
    let deckLayer = null;

    const deck = window.deck;
    const WeatherLayers = window.WeatherLayers;

    WeatherLayers.setClientConfig({
      accessToken:
        process.env.VUE_APP_WETHERLAYERS_TOKEN || "3mY9d5GFBHh9kD0Clv37",
    });

    const update = () => {
      deckLayer.setProps({
        layers: [
          new WeatherLayers.RasterLayer({
            id: "raster",
            visible: true,
            dataset: config.dataset,
            datetime: currentDatetime.value,
            datetimeInterpolate: config.datetimeInterpolate,
            imageInterpolate: config.imageInterpolate,
            opacity: config.raster.opacity,
            pickable: !isIOS15(),
          }),
        ],
      });

      deckgl.setProps({
        layers: [
          new WeatherLayers.ParticleLayer({
            id: "particle",
            visible: true,
            dataset: config.dataset,
            datetime: currentDatetime.value,
            datetimeInterpolate: config.datetimeInterpolate,
            imageInterpolate: config.imageInterpolate,
            numParticles: config.particle.numParticles,
            maxAge: config.particle.maxAge,
            speedFactor: config.particle.speedFactor,
            width: config.particle.width,
            color: colorToArray(config.particle.color),
            opacity: config.particle.opacity,
            animate: config.particle.animate,
            getPolygonOffset: () => [0, -1000],
          }),
        ],
      });
    };

    onMounted(async () => {
      loading.start();

      config = await initConfig();

      voyages.fetchVoyages().then(() => {
        state.datetime = config.datetime;
        state.datetimes = config.datetimes.map((item) => {
          return {
            value: item,
            // label: format(new Date(item), "d HH mm"),
            label: format(new Date(item), "d H"),
          };
        });

        const map = new mapboxgl.Map({
          container: "mapbox",
          style: process.env.VUE_APP_MAPBOX_STYLE,
          center: [50.80925310310907, -0.1361937699561519],
          zoom: 3,
        });

        deckgl = new deck.Deck({
          parent: document.getElementById("deck"),
          initialViewState: {},
          controller: false,
          views: [new deck.MapView({ repeat: true })],
          layers: [],
        });

        deckLayer = new deck.MapboxLayer({
          type: class extends deck.CompositeLayer {
            renderLayers() {
              return this.props.layers;
            }
          },
          id: "deck",
          layers: [],
        });

        const updateDeckView = () => {
          const viewState = {
            longitude: map.getCenter().lng,
            latitude: map.getCenter().lat,
            zoom: map.getZoom(),
            pitch: map.getPitch(),
            bearing: map.getBearing(),
          };

          deckgl.setProps({ viewState });
        };

        map.on("move", updateDeckView);
        map.on("zoom", updateDeckView);

        map.on("load", () => {
          map.addLayer(deckLayer, "waterway-label");

          map.addControl(new ZoomControl(), "top-right");

          map.addSource("route", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: [
                  [50.80925310310907, -0.1361937699561519],
                  [67.80925310310907, -1],
                  [72.80925310310907, -2],
                  [78.80925310310907, -3],
                  [82.80925310310907, -4],
                  [85.80925310310907, -4.5],
                  [90.80925310310907, -4.7],
                ],
              },
            },
          });

          map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#587AFF",
              "line-width": 2,
              "line-dasharray": [2, 3],
            },
          });
        });

        update();

        const mapOverlayButton = document.getElementById("map-overlay-button");

        mapOverlayButton.addEventListener("click", () => {
          var element = document.getElementById("map-overlay-bar");

          element.classList.toggle("hide");
        });

        loading.finish();
      });
    });

    return {
      icons,
      state,
      currentDatetime,
      update,
      list,
      searchQuery,
      searchedlist,
      selectVoyage,
    };
  },

  components: {},
});
</script>

<style lang="less" scoped>
.info-control {
  position: absolute;
  top: 0;
  left: 15px;
  min-width: 250px;
}
.info-control > div {
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.5);
  color: #eee;
  backdrop-filter: blur(1px);
}
.info-control > div:empty {
  display: none;
}
.info-control .grid-2 {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.4ex; /* space */
}
.info-control .grid-4 {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 0.4ex; /* space */
}

#container {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 0;
}

#mapbox,
#maplibre,
#deck {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mapbox #deck,
.maplibre #deck {
  pointer-events: none;
}

.timeline {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: #212125;
  padding: 0 30px;
}
.timeline :deep(.ant-slider) {
  margin: 0 !important;
}
.timeline :deep(.ant-slider-rail) {
  background-color: transparent !important;
}

/* .timeline :deep(.ant-slider-dot) {
  width: 2px;
  height: 40px;
  background-color: #f50909 !important;
  border-radius: 0 !important;
} */

.map-overlay {
  position: absolute;
  width: 330px;
  top: 72px;
  left: 12px;
  display: flex;

  &-button {
    width: 58px;
    height: 52px;
    padding: 0;
    outline: none;
    border: 0;
    box-sizing: border-box;
    background: #27282c;
  }

  &-bar {
    width: 272px;
    background-color: @color-black;
    border-right: 0.1rem solid @color-border;

    &.hide {
      display: none;
    }

    &-header {
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;

      .search {
        position: relative;
        width: 231px;

        .ant-input {
          border-radius: 0px;
        }
      }
    }

    &-body {
      background-color: @color-body;
      overflow: scroll;
      max-height: calc(100vh - 220px);

      a {
        color: #e6e8ef;
        display: block;
        font-size: 1.6rem;
        padding: 1.4rem 2.4rem;
        border-bottom: 1px solid #4b4c53;

        &.active {
          background-color: @color-active;
          border-bottom: none;
        }
      }
    }
  }

  &-content {
    width: calc(100% - 29.2rem);
    display: flex;
    flex-direction: column;

    &-header {
      height: 8rem;
      background-color: @color-body;
      border-bottom: 0.1rem solid @color-border;
      display: flex;
      align-items: center;
      padding: 0 2.2rem;
      text-transform: uppercase;
    }
  }

  &-inner {
    height: calc(100vh - 18.6rem);
    display: flex;
  }
}
</style>
